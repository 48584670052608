<template>
  <vs-prompt
    id="dialog__opt-out"
    type="alert"
    title="Are you sure?"
    icon-pack="feather"
    close-icon="icon-x"
    :active="active"
    accept-text="Yes"
    cancel-text="No"
    button-cancel="border"
    @accept="submit"
    @cancel="close"
    @close="close"
  >
    <p>You will not receive any more messages from RevenueJump.</p>
  </vs-prompt>
</template>

<script>
import { http } from '@/ky';

export default {
  name: 'RjOptOutDialog',

  props: {
    active: {
      type: Boolean,
      required: false,
    },

    contactId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    async submit() {
      const payload = { id: this.contactId, doNotContact: true };
      await http.patch(`contacts/${this.contactId}`, { json: payload });
      this.close();
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
