<template>
  <header class="mt-16">
    <img
      :src="logoUrl"
      alt="Company Logo"
      class="w-full h-auto"
    >
  </header>
</template>

<script>
export default {
  name: 'RjAppHeader',

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  computed: {
    logoUrl() {
      const { id, logo } = this.company;
      if (logo && /https?:\/\//.test(logo)) {
        return logo;
      }
      if (logo) {
        return `${process.env.VUE_APP_RJ_COMPANY_LOGO_URL}/${id}/${logo}`;
      }
      return null;
    },
  },
};
</script>
