/**
 * Builds the url query string
 *
 * @param  {Object} data
 * @return {string}
 */
export function httpBuildQuery(data) {
  return Object.entries(data).map(([key, val]) => `${key}=${val}`).join('&');
}

export function tap(value, fn) {
  fn(value);
  return value;
}
