<template>
  <footer class="text-sm text-center">
    <p class="mb-3">
      <span>{{ company.name }} </span>
      &bullet;
      <span>{{ company.addressStreet }}</span>
    </p>

    <p>
      {{ new Date().getFullYear() }} &copy; RevenueJump. All rights reserved
    </p>

    <p class="space-x-6 mt-1">
      <a
        href="#"
        class="hover:underline"
        @click.prevent="optOutDialog = true"
      >
        Opt-out
      </a>

      <a
        href="https://www.revenuejump.com/privacy/"
        class="hover:underline"
        target="_blank"
      >
        Privacy Pledge
      </a>
    </p>

    <rj-opt-out-dialog
      v-if="optOutDialog"
      :contact-id="contactId"
      :active.sync="optOutDialog"
    />
  </footer>
</template>

<script>
import RjOptOutDialog from '@/components/RjOptOutDialog.vue';

export default {
  name: 'RjAppFooter',

  components: { RjOptOutDialog },

  props: {
    contactId: {
      type: Number,
      required: true,
    },

    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      optOutDialog: false,
    };
  },
};
</script>
