<template>
  <section
    v-if="!loading"
    class="px-6 py-3 sm:px-0 max-w-full sm:max-w-sm container mx-auto"
  >
    <rj-app-header :company="company" />

    <main class="my-8">
      <router-view
        :survey="survey"
        :company="company"
        :contact="contact"
        :funnel="funnel"
      />
    </main>

    <rj-app-footer
      :company="company"
      :contact-id="contact.id"
    />
  </section>
</template>

<script>
import { http } from '@/ky';
import { httpBuildQuery, tap } from '@/utils';
import RjAppHeader from '@/components/RjAppHeader.vue';
import RjAppFooter from '@/components/RjAppFooter.vue';

export default {
  name: 'RjBaseSurvey',

  components: {
    RjAppHeader,
    RjAppFooter,
  },

  data() {
    return {
      survey: null,
      company: null,
      contact: null,
      funnel: null,
      loading: true,
    };
  },

  watch: {
    '$route.params.code': {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch(code) {
      const query = httpBuildQuery({
        filter: JSON.stringify({
          where: { url: code },
          include: [
            'company',
            'contact',
            'surveyTemplate',
          ],
        }),
      });

      const surveys = await http.get(`surveys?${query}`).json();
      // eslint-disable-next-line
      tap(surveys[0], ({ company, surveyTemplate, contact, ...survey }) => {
        this.company = company;
        this.contact = contact;
        this.funnel = surveyTemplate;
        this.survey = survey;
      });

      this.recordLastVisit(this.survey);
      this.redirectTo(this.survey.type);
      this.loading = false;
    },

    recordLastVisit(survey) {
      if (survey.status === 'Sent' || survey.status === 'Visited') {
        const payload = {
          id: survey.id,
          status: 'Visited',
          pageVisited: true,
          pageVisitedAt: new Date().toISOString(),
        };
        http.patch(`surveys/${survey.id}`, { json: payload });
      }
    },

    redirectTo(type) {
      if (this.$route.name !== 'base-survey') {
        return;
      }

      this.$router.replace({ name: type.toLowerCase() });
    },
  },
};
</script>
